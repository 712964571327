import { Col, Row } from "antd";
import Typography from "../../../components/Typography";
import { Header, TextWrapper, IconsWrapper, IconItem, StyledButton, ButtonWrapper } from "./styled";
import useMobile from "../../../hooks/useMobile";
import Image from "next/image";
import Link from "next/link";
import authorityData from "../EEAT/_authority.json";

const HEADER_FONT_SIZE = 3;
const HEADER_FONT_SIZE_MOBILE = 2.16;

export default function Banner() {
  const isMobile = useMobile();
  const TEXT_ALIGN = isMobile ? "center" : "left";

  return (
    <Header>
      <div className="image-wrapper">
        <Image
          src={!isMobile 
            ? "/images/home_page/hp-closet.png" 
            : "/images/home_page/hp-closet.png"}
          layout="fill"
          priority
          objectFit="cover"
          objectPosition={isMobile ? "center 15%" : "center center"}
          quality={isMobile ? 100 : 100}
          loading="eager"
          className="banner-image"
          alt="custom closets"
        />
      </div>
      <TextWrapper>
        <Typography
          color="white"
          as="h1"
          fontSize={isMobile ? `${HEADER_FONT_SIZE_MOBILE / 1.1}rem` : `${HEADER_FONT_SIZE / 0.8}rem`}
          textAlign={TEXT_ALIGN}
          fontFamily="Roboto Medium"
          lineHeight={1 * 1}
          className="headDropShadow"
          style={{ whiteSpace: 'nowrap' }}
        >
          Your Closet, Your Style,
        </Typography>
        <Typography
          as="span"
          href="/products/closets"
          color="white"
          fontSize={isMobile ? `${HEADER_FONT_SIZE_MOBILE / 1.1}rem` : `${HEADER_FONT_SIZE / 0.8}rem`}
          textAlign={TEXT_ALIGN}
          fontFamily="Roboto Light"
          className="headDropShadow"
          lineHeight={1 * 1}
        >
          Your</Typography>        
          <Typography
          as="span"
          href="/products/closets"
          color="white"
          fontSize={isMobile ? `${HEADER_FONT_SIZE_MOBILE / 1.1}rem` : `${HEADER_FONT_SIZE / 0.8}rem`}
          textAlign={TEXT_ALIGN}
          fontFamily="Roboto Medium"
          className="headDropShadow"
          lineHeight={1 * 1}
        > <em>Life.</em> Organized.
        </Typography>
        <IconsWrapper>
          {authorityData.map((item) => (
            <IconItem key={item.id}>
              <Image
                src={item.icon}
                alt={item.title}
                width={40}
                height={40}
                quality={70}
              />
              <span className="icon-title">{item.title}</span>
            </IconItem>
          ))}
        </IconsWrapper>
      </TextWrapper>
      <ButtonWrapper>
        <Link href="/get-in-touch" passHref>
          <StyledButton>GET STARTED TODAY</StyledButton>
        </Link>
      </ButtonWrapper>
    </Header>
  );
}
